<template>
  <div>
    Profile
  </div>
</template>

<script>

export default {
  name: 'Profile',
  components: {
  }
}
</script>
